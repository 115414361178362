export enum CustomerActionTypes {
    API_RESPONSE_SUCCESS = '@@customer/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@customer/API_RESPONSE_ERROR',
    ADD_CUST = '@@customer/ADD_CUST',
    UPD_CUST = '@@customer/UPD_CUST',
    CUST_GROUP = '@@customer/CUST_GROUP',
    TERM_ID = '@@customer/TERM_ID',
    SALE_MAN = '@@customer/SALE_MAN',
    SALE_CAT = '@@customer/SALE_CAT',
    OPEN_BALANCE = '@@customer/OPEN_BALANCE',
    UPD_BALANCE = '@@customer/UPD_BALANCE',
    MULTIPLE_CUSTOMERS_UPD = '@@customer/MULTIPLE_CUSTOMERS_UPD',
    MULTIPLE_CUSTOMERS = '@@customer/MULTIPLE_CUSTOMERS',
    ADD_CARRIER = '@@customer/ADD_CARRIER',
    ADD_CASHCOUNTER = '@@customer/ADD_CASHCOUNTER',
    RESET = '@@customer/RESET',
}