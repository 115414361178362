// constants
import { ServicesActionTypes } from './constants';

export type ServicesActionType = {
    type:
        | ServicesActionTypes.API_RESPONSE_SUCCESS
        | ServicesActionTypes.API_RESPONSE_ERROR
        | ServicesActionTypes.RESET
        | ServicesActionTypes.ADD_SERVICES
        | ServicesActionTypes.UPD_SERVICES
        | ServicesActionTypes.ADD_QUERY
        | ServicesActionTypes.ADD_TOOLS
        | ServicesActionTypes.ADD_SERVICESORDER
        | ServicesActionTypes.UPD_SERVICESORDER
        | ServicesActionTypes.ADD_SERVICES_PERSON
    payload: {} | string;
};

type InvoiceData = {
    name: string;
    


};

// common success
export const ServicesApiResponseSuccess = (actionType: string, data: InvoiceData | {}): ServicesActionType => ({
    type: ServicesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const ServicesApiResponseError = (actionType: string, error: string): ServicesActionType => ({
    type: ServicesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addQuery = (name:string): ServicesActionType => ({
    type: ServicesActionTypes.ADD_QUERY,
    payload: {name},
});
export const addTools = (name:string): ServicesActionType => ({
    type: ServicesActionTypes.ADD_TOOLS,
    payload: {name},
});

export const addServicePerson = (name:string): ServicesActionType => ({
    type: ServicesActionTypes.ADD_SERVICES_PERSON,
    payload: {name},
});

export const addServicesOrder = (entrydate:string,sourceid:string,customerid:string,salestype:string,servicepersonid:string,reference:string,notes:string,discount:string,amount:string,netamount:string,toolid:string,tax:string,taxamount:string ,pickuppostal:string ,pickupaddress:string,dropoffpostal:string,dropoffaddress:string, orderdetdata:Object): ServicesActionType => ({
    type: ServicesActionTypes.ADD_SERVICESORDER,
    payload: { entrydate, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress, orderdetdata},
});

export const updServicesOrder = (id:string,entrydate:string,sourceid:string,customerid:string,salestype:string,servicepersonid:string,reference:string,notes:string,discount:string,amount:string,netamount:string,toolid:string,tax:string,taxamount:string,pickuppostal:string ,pickupaddress:string,dropoffpostal:string,dropoffaddress:string,orderdetdata:Object): ServicesActionType => ({
    type: ServicesActionTypes.UPD_SERVICESORDER,
    payload: {id ,entrydate, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress, orderdetdata},
});
export const addServices = (orderid:string,entrydate:string,name:string,phone:string,address:string,sourceid:string,customerid:string,salestype:string,servicepersonid:string,reference:string,notes:string,discount:string,amount:string,netamount:string,toolid:string,tax:string,taxamount:string,bankreceived:string,bankid:string,cashreceived:string,pickuppostal:string ,pickupaddress:string,dropoffpostal:string,dropoffaddress:string,orderdetdata:Object): ServicesActionType => ({
    type: ServicesActionTypes.ADD_SERVICES,
    payload: { orderid,entrydate, name,
        phone,
        address,sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,bankreceived,
        bankid,
        cashreceived,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress, orderdetdata},
});

export const updServices = (id:string,orderid:string,entrydate:string,name:string,phone:string,address:string,sourceid:string,customerid:string,salestype:string,servicepersonid:string,reference:string,notes:string,discount:string,amount:string,netamount:string,toolid:string,tax:string,taxamount:string,bankreceived:string,bankid:string,cashreceived:string,pickuppostal:string ,pickupaddress:string,dropoffpostal:string,dropoffaddress:string,orderdetdata:Object): ServicesActionType => ({
    type: ServicesActionTypes.UPD_SERVICES,
    payload: {id ,orderid,entrydate,name,
        phone,
        address, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,bankreceived,
        bankid,
        cashreceived,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,orderdetdata},
});

export const resetServices = (): ServicesActionType => ({
    type: ServicesActionTypes.RESET,
    payload: {},
});
