import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useFinancialYear } from '../../hooks';
interface FinancialYearContextType {
    finYear: any[]; 
    selectFinYear: any;
    handleChange: (option: any) => void; 
    handleNewFinancialYearCreation: () => void;
}
export const FinancialYearContext = createContext<FinancialYearContextType | undefined>(undefined);

interface FinancialYearProviderProps {
    children: ReactNode;
}

export const FinancialYearProvider: React.FC<FinancialYearProviderProps> = ({ children }) => {
    const [render, setRender] = useState(false);
    const { finYear, selectFinYear, handleChange, finYearOptions } = useFinancialYear();

    useEffect(() => {
        finYearOptions();
    }, [render]);

    const handleNewFinancialYearCreation = () => {
        finYearOptions();
        setRender(prev => !prev); 
    };

    return (
        <FinancialYearContext.Provider value={{ finYear, selectFinYear, handleChange, handleNewFinancialYearCreation }}>
            {children}
        </FinancialYearContext.Provider>
    );
};
