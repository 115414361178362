export enum SupplierActionTypes {
    API_RESPONSE_SUCCESS = '@@supplier/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@supplier/API_RESPONSE_ERROR',
    ADD_SUPPLIER = '@@supplier/ADD_SUPPLIER',
    ADD_MULTIPLE_SUPPLIER = '@@supplier/ADD_MULTIPLE_SUPPLIER',
    UPD_MULTIPLE_SUPPLIER = '@@supplier/UPD_MULTIPLE_SUPPLIER',
    UPD_SUPPLIER = '@@supplier/UPD_SUPPLIER',
    OPEN_VEN_BALANCE = '@@supplier/OPEN_VEN_BALANCE',
    VEN_GROUP = '@@supplier/VEN_GROUP',
    RESET = '@@supplier/RESET',
}