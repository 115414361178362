import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// helpers
import {
    addPurchaseInv, updPurchaseInv, add_supplierPayment, upd_supplierPayment, addPurchaseReturn_API,
    updPurchaseReturn_API,addPurchaseOrder,
    updPurchaseOrder,addGRN_API,
    updGRN_API,supAddCedit_API,
    supUpdCedit_API,
    supAddDebit_API,
    supUpdDebit_API,
    UpdServiceBill_API,
    supServiceBill_API,
} from '../../helpers';

// actions
import { purchaseApiResponseSuccess, purchaseApiResponseError } from './actions';

// constants
import { PurchaseActionTypes } from './constants';


type PurchaseData = {
    payload: {
        entrydate: string; supplierid: string; transtype: string; reference: string; notes: string; freight: string; totalDiscount: string; totalprice: string; AdvancePayment: string; subtotal: string; expense: string; netamount: string; custfreight: boolean; custexpense: boolean; name: string; phone: string; address: string; invoicetermsid: string; shipped_via: string; trackNumber: string;grnvoucherid:string; purchasedetdata: Object

    };
    type: string;
};
type PurchaseOrderData = {
    payload: {
        id: string; entrydate: string; supplierid: string; transtype: string; reference: string; notes: string; freight: string; totalDiscount: string; totalprice: string; receivedate: string; subtotal: string; expense: string; netamount: string; custfreight: boolean; custexpense: boolean; name: string; phone: string; address: string; invoicetermsid: string; shipped_via: string; trackNumber: string; purchasedetdata: Object

    };
    type: string;
};
type UpdPurchaseData = {
    payload: {
        id: string; entrydate: string; supplierid: string; transtype: string; reference: string; notes: string; freight: string; totalDiscount: string; totalprice: string; AdvancePayment: string; subtotal: string; expense: string; netamount: string; custfreight: boolean; custexpense: boolean; name: string; phone: string; address: string; invoicetermsid: string; shipped_via: string; trackNumber: string; grnvoucherid:string;purchasedetdata: Object

    };
    type: string;
};
type payment_receipt_types = {
    payload: {
        id: string; supplierid: string; amount: string; paymethod: string; description: string; entrydate: string; checkNum: string; bank: string; duedate: string; deposit: string; reference: string
    };
    type: Object;
};
type PurReturnData = {
    payload: {
        entrydate: string; purchasenumber: string; supplierid: string; transtype: string; reference: string; notes: string; freight: string; totalDiscount: string; totalprice: string; AdvancePayment: string; subtotal: string; expense: string; netamount: string; custfreight: boolean; custexpense: boolean; name: string; phone: string; address: string;invoicetermsid:string;shipped_via:string;trackNumber:string; purchasedetdata: Object

    };
    type: string;
};
type UpdPurReturnData = {
    payload: {
        id: string; entrydate: string; purchasenumber: string; supplierid: string; transtype: string; reference: string; notes: string; freight: string; totalDiscount: string; totalprice: string; AdvancePayment: string; subtotal: string; expense: string; netamount: string; custfreight: boolean; custexpense: boolean; name: string; phone: string; address: string;invoicetermsid:string;shipped_via:string;trackNumber:string; purchasedetdata: Object

    };
    type: string;
};


type GRNData = {
    payload: {
        id:string; entrydate: string; supplierid: string; transtype: string; reference: string; notes: string; freight: string; totalDiscount: string; totalprice: string; subtotal: string; expense: string; netamount: string; custfreight: boolean; custexpense: boolean; name: string; phone: string; address: string; invoicetermsid: string; shipped_via: string; trackNumber: string;orderid:string; receivedate:string; purchasedetdata: Object

    };
    type: string;
};
type debitCredit_type = {
    payload: {id:string;supplierid:string;entrydate:string;description:string;totalamount:string;voucherdetdata:[]};
    type: string;
};
const api = new APICore();

function* addPurchase({ payload: { entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, AdvancePayment, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid, shipped_via, trackNumber,grnvoucherid, purchasedetdata } }: PurchaseData): SagaIterator {
    try {
        const response = yield call(addPurchaseInv, { entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, AdvancePayment, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid, shipped_via, trackNumber,grnvoucherid, purchasedetdata, });
        console.log(response);
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.ADD_PURCHASEINV, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.ADD_PURCHASEINV, error));
    }
}
function* updPurchase({ payload: { id, entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, AdvancePayment, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid, shipped_via, trackNumber, grnvoucherid,purchasedetdata } }: UpdPurchaseData): SagaIterator {
    try {
        const response = yield call(updPurchaseInv, { id, entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, AdvancePayment, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid, shipped_via, trackNumber,grnvoucherid, purchasedetdata });
        console.log(response);
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.UPD_PURCHASEINV, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.UPD_PURCHASEINV, error));
    }
}
function* addPurOrder({ payload: { entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, receivedate, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid, shipped_via, trackNumber, purchasedetdata } }: PurchaseOrderData): SagaIterator {
    try {
        const response = yield call(addPurchaseOrder, { entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, receivedate, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid, shipped_via, trackNumber, purchasedetdata, });
        console.log(response);
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.ADD_PURCHASEORDER, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.ADD_PURCHASEORDER, error));
    }
}
function* updPurOrder({ payload: { id, entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, receivedate, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid, shipped_via, trackNumber, purchasedetdata } }: PurchaseOrderData): SagaIterator {
    try {
        const response = yield call(updPurchaseOrder, { id, entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, receivedate, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid, shipped_via, trackNumber, purchasedetdata });
        console.log(response);
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.UPD_PURCHASEORDER, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.UPD_PURCHASEORDER, error));
    }
}
function* addPurchaseReturn({ payload: { entrydate, purchasenumber, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, AdvancePayment, subtotal, expense, netamount, custfreight, custexpense, name, phone, address,invoicetermsid,shipped_via,trackNumber, purchasedetdata } }: PurReturnData): SagaIterator {
    try {
        const response = yield call(addPurchaseReturn_API, { entrydate, purchasenumber, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, AdvancePayment, subtotal, expense, netamount, custfreight, custexpense, name, phone, address,invoicetermsid,shipped_via,trackNumber, purchasedetdata });
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.ADD_PURCHASE_RETURN, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.ADD_PURCHASE_RETURN, error));
    }
}
function* updPurchaseReturn({ payload: { id, entrydate, purchasenumber, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, AdvancePayment, subtotal, expense, netamount, custfreight, custexpense, name, phone, address,invoicetermsid,shipped_via,trackNumber, purchasedetdata } }: UpdPurReturnData): SagaIterator {
    try {
        const response = yield call(updPurchaseReturn_API, { id, entrydate, purchasenumber, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, AdvancePayment, subtotal, expense, netamount, custfreight, custexpense, name, phone, address,invoicetermsid,shipped_via,trackNumber, purchasedetdata });
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.UPD_PURCHASE_RETURN, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.UPD_PURCHASE_RETURN, error));
    }
}

function* addGrn({ payload: { entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, receivedate, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid, shipped_via, trackNumber,orderid, purchasedetdata } }: GRNData): SagaIterator {
    try {
        const response = yield call(addGRN_API, { entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, receivedate, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid, shipped_via, trackNumber,orderid, purchasedetdata, });
        console.log(response);
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.ADD_GRN, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.ADD_GRN, error));
    }
}

/*
function* addGrn({ payload: { entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, receivedate, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid, shipped_via, trackNumber,orderid, purchasedetdata } }: GRNData): SagaIterator {
    try {
        const response = yield call(addGRN_API, { payload: { entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, receivedate, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid, shipped_via, trackNumber,orderid, purchasedetdata,} });
        console.log(response);
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.ADD_GRN, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.ADD_GRN, error));
    }
}
*/

function* updGrn({ payload: {id,  entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, receivedate, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid,orderid, shipped_via, trackNumber, purchasedetdata } }: GRNData): SagaIterator {
    try {
        const response = yield call(updGRN_API, {id,  entrydate, supplierid, transtype, reference, notes, freight, totalDiscount, totalprice, receivedate, subtotal, expense, netamount, custfreight, custexpense, name, phone, address, invoicetermsid,orderid, shipped_via, trackNumber, purchasedetdata  });
        console.log(response);
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.UPD_GRN, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.UPD_GRN, error));
    }
}

/*
function* updGrn({ payload: { id, entrydate, orderid, supplierid, transtype, reference, notes, totalprice, receivedate, subtotal, netamount, name, phone, address, purchasedetdata } }: GRNData): SagaIterator {
    try {
        const response = yield call(updGRN_API, { id, entrydate, orderid, supplierid, transtype, reference, notes, totalprice, receivedate, subtotal, netamount ,name, phone, address, purchasedetdata });
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.UPD_GRN, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.UPD_GRN, error));
    }
}
*/

function* addVenPay({ payload: { supplierid, amount, paymethod, description, entrydate, checkNum, bank, duedate, deposit, reference } }: payment_receipt_types): SagaIterator {
    try {
        const response = yield call(add_supplierPayment, { supplierid, amount, paymethod, description, entrydate, checkNum, bank, duedate, deposit, reference });
        console.log(response);
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.SUPPLIER_PAYMENT, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.SUPPLIER_PAYMENT, error));
    }
}
function* updVenPay({ payload: { id, supplierid, amount, paymethod, description, entrydate, checkNum, bank, duedate, deposit, reference } }: payment_receipt_types): SagaIterator {
    try {
        const response = yield call(upd_supplierPayment, { id, supplierid, amount, paymethod, description, entrydate, checkNum, bank, duedate, deposit, reference });
        console.log(response);
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.UPD_SUPPLIER_PAYMENT, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.UPD_SUPPLIER_PAYMENT, error));
    }
}
function* addCreditNote({ payload: {entrydate,supplierid,description,totalamount,voucherdetdata} }: debitCredit_type): SagaIterator {
    try {
        const response = yield call(supAddCedit_API, {entrydate,supplierid,description,totalamount,voucherdetdata});
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.SUPP_CREDIT_NOTE, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.SUPP_CREDIT_NOTE, error));
            }
}
function* addServiceBill({ payload: {entrydate,supplierid,description,totalamount,voucherdetdata} }: debitCredit_type): SagaIterator {
    try {
        const response = yield call(supServiceBill_API, {entrydate,supplierid,description,totalamount,voucherdetdata});
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.ADD_SERVICE_BILL, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.ADD_SERVICE_BILL, error));
            }
}
function* updCreditNote({ payload: {id,entrydate,supplierid,description,totalamount,voucherdetdata} }: debitCredit_type): SagaIterator {
    try {
        const response = yield call(supUpdCedit_API, {id,entrydate,supplierid,description,totalamount,voucherdetdata});
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.SUPP_UPD_CREDIT_NOTE, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.SUPP_UPD_CREDIT_NOTE, error));
            }
}
function* addDebitNote({ payload: {entrydate,supplierid,description,totalamount,voucherdetdata} }: debitCredit_type): SagaIterator {
    try {
        const response = yield call(supAddDebit_API, {entrydate,supplierid,description,totalamount,voucherdetdata});
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.SUPP_DEBIT_NOTE, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.SUPP_DEBIT_NOTE, error));
            }
}
function* updDebitNote({ payload: {id,entrydate,supplierid,description,totalamount,voucherdetdata} }: debitCredit_type): SagaIterator {
    try {
        const response = yield call(supUpdDebit_API, {id,entrydate,supplierid,description,totalamount,voucherdetdata});
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.SUPP_UPD_DEBIT, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.SUPP_UPD_DEBIT, error));
            }
}

function* updServicesBill({ payload: {id,entrydate,supplierid,description,totalamount,voucherdetdata} }: debitCredit_type): SagaIterator {
    try {
        const response = yield call(UpdServiceBill_API, {id,entrydate,supplierid,description,totalamount,voucherdetdata});
        const item = response.data;
        yield put(purchaseApiResponseSuccess(PurchaseActionTypes.UPD_SERVICES_BILL, item));
    } catch (error: any) {
        yield put(purchaseApiResponseError(PurchaseActionTypes.UPD_SERVICES_BILL, error));
            }
}


export function* watchAdd(): any {
    yield takeEvery(PurchaseActionTypes.ADD_PURCHASEINV, addPurchase);
    yield takeEvery(PurchaseActionTypes.ADD_PURCHASEORDER, addPurOrder);
    yield takeEvery(PurchaseActionTypes.ADD_GRN, addGrn);
    yield takeEvery(PurchaseActionTypes.UPD_GRN, updGrn);
    yield takeEvery(PurchaseActionTypes.UPD_PURCHASEORDER, updPurOrder);
    yield takeEvery(PurchaseActionTypes.UPD_PURCHASEINV, updPurchase);
    yield takeEvery(PurchaseActionTypes.ADD_PURCHASE_RETURN, addPurchaseReturn);
    yield takeEvery(PurchaseActionTypes.UPD_PURCHASE_RETURN, updPurchaseReturn);
    yield takeEvery(PurchaseActionTypes.SUPPLIER_PAYMENT, addVenPay);
    yield takeEvery(PurchaseActionTypes.UPD_SERVICES_BILL, updServicesBill);
    yield takeEvery(PurchaseActionTypes.UPD_SUPPLIER_PAYMENT, updVenPay);
    yield takeEvery(PurchaseActionTypes.SUPP_DEBIT_NOTE, addDebitNote);
    yield takeEvery(PurchaseActionTypes.SUPP_UPD_DEBIT, updDebitNote);
    yield takeEvery(PurchaseActionTypes.SUPP_CREDIT_NOTE, addCreditNote);
    yield takeEvery(PurchaseActionTypes.SUPP_UPD_CREDIT_NOTE, updCreditNote);
    yield takeEvery(PurchaseActionTypes.ADD_SERVICE_BILL, addServiceBill);
    
}


function* purchaseSaga() {
    yield all([fork(watchAdd)]);
}

export default purchaseSaga;
