// apicore
import { APICore } from '../../helpers/api/apiCore';

// constants
import { EmployeeActionTypes } from './constants';

const api = new APICore();

const INIT_STATE = {
    EmpAdded: null,
    EmpUpd: null,
    addedDes: null,
    addedDepart: null,
    addedJobtype: null,
    addedAssettype: null,
    addedBonustype: null,
    addedGrades: null,
    addedQuali: null,  
    addedShift: null,  
    addedGroups: null,
    addedDoctype: null,
    addedWork: null,
    addedOccu: null,
    addedRela: null,
    addedNati: null,
    loading: false,
    modalLoading: false,
    openBalance:null,
    error:null,
};

type EmpData = {
    cardno:string;empname:string;fname:string;dateofbirth:string;gender:string;cnic:string;mainaddress:string;cityid:string;martialStatus:string;religionid:string;phone:string;alterPhone:string;email:string;contactperson:string;phone2:string;address:string;joindate:string;jobtypeid:string;departmentid:string;designationid:string;salary:string;cleave:string;sickleave:string;annualleave:string;startdate:string;enddate:string;description:string;incrementPer:string;inctype:string;workingdays:string;timefrom:string;timeto:string;codeofconduct:string;bonusgrid:object;assetsgrid:object;backgroundgrid:object;documentgrid:object
};


type EmployeeActionType = {
    type:
        | EmployeeActionTypes.API_RESPONSE_SUCCESS
        | EmployeeActionTypes.API_RESPONSE_ERROR
        | EmployeeActionTypes.ADD_EM
        | EmployeeActionTypes.UPD_EM
        | EmployeeActionTypes.ADD_DESIG
        | EmployeeActionTypes.ADD_DEPART
        | EmployeeActionTypes.ADD_JOBTYPE
        | EmployeeActionTypes.ADD_ASSET
        | EmployeeActionTypes.ADD_SHIFT
        | EmployeeActionTypes.ADD_GRADES
        | EmployeeActionTypes.ADD_QUALI
        | EmployeeActionTypes.ADD_GROUPS
        | EmployeeActionTypes.ADD_NATI
        | EmployeeActionTypes.ADD_OCCU
        | EmployeeActionTypes.ADD_WORK
        | EmployeeActionTypes.ADD_RELA
        | EmployeeActionTypes.ADD_BONUS
        | EmployeeActionTypes.ADD_DOC
        | EmployeeActionTypes.OPEN_EMPLOYEE_BALANCE
        | EmployeeActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: EmpData | null;
        loading: boolean;
        error?: string;
    };
};

type State = {
    EmpAdded?: EmpData | null;
    loading?: boolean;
    value?: boolean;
};

const Employee = (state: State = INIT_STATE, action: EmployeeActionType): any => {
    switch (action.type) {
        case EmployeeActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case EmployeeActionTypes.ADD_EM: {
                    return {
                        ...state,
                        EmpAdded: action.payload,
                        loading: false,
                    };
                }
                case EmployeeActionTypes.UPD_EM: {
                    return {
                        ...state,
                        EmpUpd: action.payload,
                        loading: false,
                    };
                }
                case EmployeeActionTypes.ADD_DESIG: {
                    return {
                        ...state,
                        addedDes: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_DEPART: {
                    return {
                        ...state,
                        addedDepart: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_QUALI: {
                    return {
                        ...state,
                        addedQuali: action.payload,
                        modalLoading: false,
                    };
                }
                                case EmployeeActionTypes.ADD_SHIFT: {
                    return {
                        ...state,
                        addedShift: action.payload,
                        modalLoading: false,
                    };
                }
                 case EmployeeActionTypes.ADD_GRADES: {
                    return {
                        ...state,
                        addedGrades: action.payload,
                        modalLoading: false,
                    };
                }
                 case EmployeeActionTypes.ADD_NATI: {
                    return {
                        ...state,
                        addedNati: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_WORK: {
                    return {
                        ...state,
                        addedWork: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_OCCU: {
                    return {
                        ...state,
                        addedOccu: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_RELA: {
                    return {
                        ...state,
                        addedRela: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_JOBTYPE: {
                    return {
                        ...state,
                        addedJobtype: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_GROUPS: {
                    return {
                        ...state,
                        addedGroups: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_ASSET: {
                    return {
                        ...state,
                        addedAssettype: action.payload,
                        modalLoading: false,
                    };
                }
            
                case EmployeeActionTypes.ADD_BONUS: {
                    return {
                        ...state,
                        addedBonustype: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_DOC: {
                    return {
                        ...state,
                        addedDoctype: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.OPEN_EMPLOYEE_BALANCE: {
                    return {
                        ...state,
                        openBalance: action.payload,
                        loading: false,
                    };
                }
                
                default:
                    return { ...state };
            }

        case EmployeeActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                
                case EmployeeActionTypes.ADD_EM: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        EmpAdded: null,
                        loading: false,
                    };
                }
                
                case EmployeeActionTypes.UPD_EM: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        EmpUpd: null,
                        loading: false,
                    };
                }
                case EmployeeActionTypes.ADD_DESIG: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedDes: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_DEPART: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedDepart: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_GRADES: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedGrades: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_JOBTYPE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedJobtype: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_GROUPS: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedGroups: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_QUALI: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedQuali: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_SHIFT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedShift: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_NATI: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedNati: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_WORK: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedWork: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_OCCU: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedOccu: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_RELA: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedRela: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_ASSET: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedAssettype: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_BONUS: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedBonustype: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_DOC: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedDoctype: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.OPEN_EMPLOYEE_BALANCE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        openBalance: null,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case EmployeeActionTypes.ADD_EM:
            return { ...state, loading: true, EmpAdded: null };
        case EmployeeActionTypes.UPD_EM:
            return { ...state, loading: true, EmpUpd: null };
        case EmployeeActionTypes.ADD_DESIG:
            return { ...state, modalLoading: true, addedDes: null };
        case EmployeeActionTypes.ADD_DEPART:
            return { ...state, modalLoading: true, addedDepart: null };
        case EmployeeActionTypes.ADD_JOBTYPE:
            return { ...state, modalLoading: true, addedJobtype: null };
        case EmployeeActionTypes.ADD_ASSET:
            return { ...state, modalLoading: true, addedJobtype: null };
        case EmployeeActionTypes.ADD_GROUPS:
            return { ...state, modalLoading: true, addedGroups: null };
        case EmployeeActionTypes.ADD_BONUS:
            return { ...state, modalLoading: true, addedBonustype: null };
        case EmployeeActionTypes.ADD_QUALI:
            return { ...state, modalLoading: true, addedQuali: null };
        case EmployeeActionTypes.ADD_SHIFT:
            return { ...state, modalLoading: true, addedShift: null };
        case EmployeeActionTypes.ADD_GRADES:
            return { ...state, modalLoading: true, addedGrades: null };
        case EmployeeActionTypes.ADD_WORK:
            return { ...state, modalLoading: true, addedWork: null };
        case EmployeeActionTypes.ADD_OCCU:
            return { ...state, modalLoading: true, addedOccu: null };
        case EmployeeActionTypes.ADD_RELA:
            return { ...state, modalLoading: true, addedRela: null };
                case EmployeeActionTypes.ADD_NATI:
            return { ...state, modalLoading: true, addedNati: null };
        case EmployeeActionTypes.ADD_DOC:
            return { ...state, modalLoading: true, addedDoctype: null };
        case EmployeeActionTypes.OPEN_EMPLOYEE_BALANCE:
            return { ...state, loading: true, openBalance: null };
        case EmployeeActionTypes.RESET:
            return {
                ...state,
                loading: false,
                modalLoading: false,
                error: null,
                EmpAdded: null,
                EmpUpd: null,
                addedGrades: null,
                addedDes: null,
                addedDepart: null,
                addedJobtype: null,
                addedRela: null,
                addedShift: null,  
                addedGroups: null,
                addedQuali: null,
                addedNati:null,
                addedWork:null,
                addedAssettype: null,
                addedBonustype: null,
                addedDoctype: null,
                openBalance: null,
             };
        default:
            return { ...state };
    }
};

export default Employee;
