import { BrowserRouter } from 'react-router-dom';
import React, { Suspense,useState,useEffect } from 'react';
import axios from 'axios'
// routes
import { AllRoutes } from './index';
import { APICore } from '../helpers/api/apiCore';
const Routes = () => {
    const api = new APICore();
   
    return (
        <BrowserRouter>
           <AllRoutes />
        </BrowserRouter>
    );
};

export default Routes;
