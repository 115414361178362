import LogoLight from '../assets/images/its logo.png';

const MainLoader = () => {
    return (
        <>
       
            <div className="" id="status" >
             <span className="logo-lg">
           <img src={LogoLight} alt="" height="90" width="90" />
        </span>
                <div className="spinner" style={{width:'92px',height:'92px', position: "absolute",top: '0px',left:'0'}}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
      </>
    );
};

export default MainLoader;
