import { LedgerActionTypes } from './constants';

const INIT_STATE = {
  groupAccounts: null,
  updgroupAccounts: null,
  addDetailAccounts: null,
  updDetailAccounts: null,
  openBalance: null,
  addCashpayments:null,
  updCashpayments:null,
  addExpenseVouchers:null,
  updExpenseVouchers:null,
  addBankpayments:null,
  updBankpayments:null,
  addCashReceipts:null,
  addBankReceipts:null,
  updBankReceipts:null,
  journalVoucher:null,
  updJV:null,
  updCashReceipts:null,
  loading: false,
  error: null,
};

const Ledgers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LedgerActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case LedgerActionTypes.ACCOUNT_GROUP:
          return {
            ...state,
            groupAccounts: action.payload,
          };
        case LedgerActionTypes.OPEN_ACC_BALANCE:
          return {
            ...state,
            openBalance: action.payload,
          };
        case LedgerActionTypes.UPD_ACCOUNT_GROUP:
          return {
            ...state,
            updgroupAccounts: action.payload,
          };
        case LedgerActionTypes.DETAIL_ACCOUNT:
          return {
            ...state,
            updDetailAccounts: action.payload,
          };
        case LedgerActionTypes.UPD_DETAIL_ACCOUNT:
          return {
            ...state,
            addDetailAccounts: action.payload,
          };
          case LedgerActionTypes.Add_CashPayment:
          return {
            ...state,
            addCashpayments: action.payload,
          };
          
          case LedgerActionTypes.Upd_CashPayment:
          return {
            ...state,
            updCashpayments: action.payload,
          };
          case LedgerActionTypes.ADD_EXPENSEVOUCHER:
          return {
            ...state,
            addExpenseVouchers: action.payload,
          };
          
          case LedgerActionTypes.UPD_EXPENSEVOUCHER:
          return {
            ...state,
            updExpenseVouchers: action.payload,
          };
          case LedgerActionTypes.ADD_BANKPAYMENT:
          return {
            ...state,
            addBankpayments: action.payload,
          };
          
          case LedgerActionTypes.UPD_BANKPAYMENT:
          return {
            ...state,
            updBankpayments: action.payload,
          };
          case LedgerActionTypes.Add_CashReceipt:
          return {
            ...state,
            addCashReceipts: action.payload,
          };
          case LedgerActionTypes.UPD_CashReceipt:
          return {
            ...state,
            updCashReceipts: action.payload,
          };
          case LedgerActionTypes.Add_JOURNALVOUCHER:
          return {
            ...state,
            journalVoucher: action.payload,
          };
          case LedgerActionTypes.UPD_JOURNALVOUCHER:
          return {
            ...state,
            updJV: action.payload,
          };
          case LedgerActionTypes.ADD_BANKRECEIPT:
          return {
            ...state,
            addBankReceipts: action.payload,
          };
          case LedgerActionTypes.UPD_BANKRECEIPT:
          return {
            ...state,
            updBankReceipts: action.payload,
          };
        default:
          return { ...state };
      }
    case LedgerActionTypes.API_RESPONSE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        groupAccounts: null,
        addDetailAccounts: null,
        updDetailAccounts: null,
        updgroupAccounts: null,
        openBalance: null,
        addCashpayments:null,
        addBankpayments:null,
        journalVoucher:null,
        updJV:null,
        updCashReceipts:null,
        updBankpayments:null,
        addBankReceipts:null,
        updBankReceipts:null,
        addExpenseVouchers:null,
        updExpenseVouchers:null,
        addCashReceipts:null,
        loading: true,
      };
    case LedgerActionTypes.ACCOUNT_GROUP:
      return { ...state, loading: true, groupAccounts: null };
    case LedgerActionTypes.OPEN_ACC_BALANCE:
      return { ...state, loading: true, openBalance: null };
    case LedgerActionTypes.UPD_ACCOUNT_GROUP:
      return { ...state, loading: true, updgroupAccounts: null };
    case LedgerActionTypes.DETAIL_ACCOUNT:
      return { ...state, loading: true, addDetailAccounts: null };
    case LedgerActionTypes.UPD_DETAIL_ACCOUNT:
      return { ...state, loading: true, updDetailAccounts: null };
    case LedgerActionTypes.Add_CashPayment:
      return { ...state, loading: true, addCashpayments: null };
    case LedgerActionTypes.Upd_CashPayment:
      return { ...state, loading: true, updCashpayments: null };
    case LedgerActionTypes.ADD_EXPENSEVOUCHER:
      return { ...state, loading: true, addExpenseVouchers: null };
    case LedgerActionTypes.UPD_EXPENSEVOUCHER:
      return { ...state, loading: true, updExpenseVouchers: null };
    case LedgerActionTypes.ADD_BANKPAYMENT:
      return { ...state, loading: true, addBankpayments: null };
    case LedgerActionTypes.UPD_BANKPAYMENT:
      return { ...state, loading: true, updBankpayments: null };
    case LedgerActionTypes.Add_CashReceipt:
      return { ...state, loading: true, addCashReceipts: null };
    case LedgerActionTypes.UPD_CashReceipt:
      return { ...state, loading: true, updCashReceipts: null };
    case LedgerActionTypes.Add_JOURNALVOUCHER:
      return { ...state, loading: true, journalVoucher: null };
    case LedgerActionTypes.ADD_BANKRECEIPT:
      return { ...state, loading: true, addBankReceipts: null };
    case LedgerActionTypes.UPD_BANKRECEIPT:
      return { ...state, loading: true, updBankReceipts: null };
    case LedgerActionTypes.UPD_JOURNALVOUCHER:
      return { ...state, loading: true, updJV: null };
    case LedgerActionTypes.RESET:
      return { ...state,openBalance:null,addBankpayments:null,addExpenseVouchers:null,updExpenseVouchers:null,addBankReceipts:null,updBankpayments:null,updBankReceipts:null,updCashReceipts:null, loading: false, groupAccounts:null,updgroupAccounts:null,addDetailAccounts:null,updDetailAccounts:null,addCashpayments:null,updCashpayments:null,addCashReceipts:null, error: false,journalVoucher:null,updJV:null };
    default:
      return { ...state };
  }
};

export default Ledgers;
