import {AppsActionTypes} from './constants'



export const appsResponseSuccess = (actionType ,data)=>({
    type: AppsActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType,data}
})
export const appsResponseError = (actionType ,error)=>({
    type: AppsActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType,error}
})
export const AddCalendarEvents =(title,className,startdate,enddate,allDay)=>({
    type:AppsActionTypes.CALENDAR_EVENT,
    payload:{title,className,startdate,enddate,allDay}
})
export const addContactInfo =(name,company,position,phone,email,cityid,address)=>({
    type:AppsActionTypes.ADD_CONTACT,
    payload:{name,company,position,phone,email,cityid,address}
})
export const userTasks =(title,assignto,assignby,priority,startdate,enddate,description)=>({
    type:AppsActionTypes.ADD_TASKS,
    payload:{title,assignto,assignby,priority,startdate,enddate,description}
})
export const addPriority =(name)=>({
    type:AppsActionTypes.ADD_PRIORITY,
    payload:{name}
})
export const addPosition =(name)=>({
    type:AppsActionTypes.ADD_POSITION,
    payload:{name}
})
export const addComment =(taskid,sendto,comment)=>({
    type:AppsActionTypes.ADD_COMMENT,
    payload:{taskid,sendto,comment}
})
export const addMessage =(from,to,message)=>({
    type:AppsActionTypes.ADD_MESSAGE,
    payload:{from,to,message}
})

export const resetEvent = () => ({
    type: AppsActionTypes.RESET,
    payload: {},
});