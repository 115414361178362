import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

function add_financialYear(params) {
    const baseUrl = '/add-financial-year';
    return api.create(`${baseUrl}`, params);
    
}
function add_userManagement(params) {
    const baseUrl = '/register-user';
    return api.create(`${baseUrl}`, params);
    
}
function dashboardRight_API(params) {
    const baseUrl = `/update-dashboard-rights/${params.userid}`;
    return api.update(`${baseUrl}`, params);
    
}
function empRight_API(params) {
    return api.create(`/save-employee-rights`, params);
    
}
function manageLogin_API(params) {
    return api.create(`/add-loginpage-theme`, params);
    
}
function roles_API(params) {
    const baseUrl = '/add-role';
    return api.create(`${baseUrl}`, params);
    
}
function updateUserApi(params) {
    const baseUrl = `update-user/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}

export {manageLogin_API,empRight_API,roles_API,add_financialYear,add_userManagement,updateUserApi,dashboardRight_API};
