import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
   addQuery_API,addTools_API,addServicesPerson_API,addServices_API,addServicesOrder_API, updServicesOrder_API,updServices_API
} from '../../helpers';
import { ServicesApiResponseSuccess, ServicesApiResponseError } from './actions';
import { ServicesActionTypes } from './constants';

type ServicesData = {
    payload: {
       id:string;orderid:string;entrydate:string;name:string;phone:string;address:string;sourceid:string;customerid:string;salestype:string;servicepersonid:string;reference:string;notes:string;discount:string;amount:string;netamount:string;toolid:string;tax:string;taxamount:string;bankreceived:string;bankid:string;cashreceived:string;pickuppostal:string;pickupaddress:string;dropoffpostal:string;dropoffaddress:string;orderdetdata:Object;};
    type: string;
};
type modalData = {
    payload: {name:string;};
    type: string;
};

function* addServicesOrder({ payload: { entrydate, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress, orderdetdata} }: ServicesData): SagaIterator {
    try {
        const response = yield call(addServicesOrder_API, { entrydate, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount, toolid,tax,taxamount,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,orderdetdata});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.ADD_SERVICESORDER, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.ADD_SERVICESORDER, error));
            }
}
function* updServicesOrder({ payload: { id,entrydate, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress, orderdetdata} }: ServicesData): SagaIterator {
    try {
        const response = yield call(updServicesOrder_API, {id,entrydate, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount, toolid,tax,taxamount,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,orderdetdata});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.UPD_SERVICESORDER, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.UPD_SERVICESORDER, error));
            }
}
function* addServices({ payload: { orderid,entrydate,name,
    phone,
    address, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,bankreceived,
bankid,
cashreceived,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress, orderdetdata} }: ServicesData): SagaIterator {
    try {
        const response = yield call(addServices_API, { orderid,entrydate,name,
            phone,
            address, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount, toolid,tax,taxamount,bankreceived,
bankid,
cashreceived,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,orderdetdata});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.ADD_SERVICES, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.ADD_SERVICES, error));
            }
}
function* addQuery({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addQuery_API, {name});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.ADD_QUERY, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.ADD_QUERY, error));
            }
}
function* addTools({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addTools_API, {name});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.ADD_TOOLS, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.ADD_TOOLS, error));
            }
}

function* addServicePerson({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addServicesPerson_API, {name});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.ADD_SERVICES_PERSON, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.ADD_SERVICES_PERSON, error));
            }
}

function* updServices({ payload: {id,orderid, entrydate,name,
    phone,
    address, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,bankreceived,
    bankid,
    cashreceived,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress, orderdetdata} }: ServicesData): SagaIterator {
    try {
        const response = yield call(updServices_API, {id,orderid, entrydate, name,
            phone,
            address,sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,bankreceived,
            bankid,
            cashreceived,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,orderdetdata});
        console.log(response);
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.UPD_SERVICES, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.UPD_SERVICES, error));
            }
}

export function* watchAdd(): any {
    yield takeEvery(ServicesActionTypes.ADD_SERVICES, addServices);
    yield takeEvery(ServicesActionTypes.UPD_SERVICES, updServices);
    yield takeEvery(ServicesActionTypes.ADD_SERVICESORDER, addServicesOrder);
    yield takeEvery(ServicesActionTypes.UPD_SERVICESORDER, updServicesOrder);
    yield takeEvery(ServicesActionTypes.ADD_QUERY, addQuery);
    yield takeEvery(ServicesActionTypes.ADD_TOOLS, addTools);
    yield takeEvery(ServicesActionTypes.ADD_SERVICES_PERSON, addServicePerson);
    }


function* ServicesSaga() {
    yield all([fork(watchAdd)]);
}

export default ServicesSaga;
