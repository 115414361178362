export enum LedgerActionTypes {
    API_RESPONSE_SUCCESS = '@@apps/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@apps/API_RESPONSE_ERROR',
    ACCOUNT_GROUP = '@@apps/ACCOUNT_GROUP',
    DETAIL_ACCOUNT = '@@apps/DETAIL_ACCOUNT',
    UPD_DETAIL_ACCOUNT = '@@apps/UPD_DETAIL_ACCOUNT',
    OPEN_ACC_BALANCE = '@@apps/OPEN_ACC_BALANCE',
    UPD_ACCOUNT_GROUP = '@@apps/UPD_ACCOUNT_GROUP',
    Add_CashPayment = '@@apps/Add_CashPayment',
    Upd_CashPayment = '@@apps/Upd_CashPayment',
    Add_CashReceipt = '@@apps/Add_CashReceipt',
    UPD_CashReceipt = '@@apps/UPD_CashReceipt',
    Add_JOURNALVOUCHER = '@@apps/Add_JOURNALVOUCHER',
    UPD_JOURNALVOUCHER = '@@apps/UPD_JOURNALVOUCHER',
    ADD_BANKPAYMENT = '@@apps/ADD_BANKPAYMENT',
    UPD_BANKPAYMENT = '@@apps/UPD_BANKPAYMENT',
    ADD_BANKRECEIPT = '@@apps/ADD_BANKRECEIPT',
    UPD_BANKRECEIPT = '@@apps/UPD_BANKRECEIPT',
    ADD_EXPENSEVOUCHER = '@@apps/ADD_EXPENSEVOUCHER',
    UPD_EXPENSEVOUCHER = '@@apps/UPD_EXPENSEVOUCHER',
    RESET = '@@apps/RESET',
}