import { CustomerActionTypes } from './constants';

const INIT_STATE = {
    custGroups:null,
    custAdded:null,
    custUpdated:null,
    saleMan:null,
    saleCate:null,
    termId:null,
    openBalance:null,
    updOpenBalance:null,
    carrier:null,
    cashcounter:null,
    multipleCustomer:null,
    multipleCustomerUPD:null,
    loading: false,
    modalLoading: false,
};

type CustData = {
    id:string; branchid: string; name: string; cellphone: string; cellphonecomment: string; cnic:string; address1:string; address2:string; city:string; state:string; zip:string; country:string; entry_firstname:string; middle_name:string; entry_lastname:string; phone1: string; phone1comment: string; phone2: string; phone2comment: string; phone4: string; phone4comment: string; phone5: string; phone5comment: string; email1: string; email1comment: string; email2: string; email2comment: string; email3: string; email3comment: string; url:string; groupid:string; default_price_level:string; salesmanid:string; invoicetermsid:string; combineSupplierId:string; crlimit:string; discount:string; notes:string;
};


type CustomerActionType = {
    type:
        | CustomerActionTypes.API_RESPONSE_SUCCESS
        | CustomerActionTypes.API_RESPONSE_ERROR
        | CustomerActionTypes.ADD_CUST
        | CustomerActionTypes.UPD_CUST
        | CustomerActionTypes.CUST_GROUP
        | CustomerActionTypes.SALE_MAN
        | CustomerActionTypes.SALE_CAT
        | CustomerActionTypes.OPEN_BALANCE
        | CustomerActionTypes.UPD_BALANCE
        | CustomerActionTypes.MULTIPLE_CUSTOMERS
        | CustomerActionTypes.MULTIPLE_CUSTOMERS_UPD
        | CustomerActionTypes.ADD_CARRIER
        | CustomerActionTypes.ADD_CASHCOUNTER
        | CustomerActionTypes.TERM_ID
        | CustomerActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: CustData | {};
        error?: string;
    };
};

type State = {
    loading?: boolean;
    value?: boolean;
};

const Customers = (state: State = INIT_STATE, action: CustomerActionType): any => {
    switch (action.type) {
        case CustomerActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CustomerActionTypes.ADD_CUST: {
                    return {
                        ...state,
                        custAdded: action.payload,
                        loading: false,
                    };
                }
                case CustomerActionTypes.UPD_CUST: {
                    return {
                        ...state,
                        custUpdated: action.payload,
                        loading: false,
                    };
                }
                 case CustomerActionTypes.MULTIPLE_CUSTOMERS : {
                    return {
                        ...state,
                        multipleCustomer: action.payload,
                        loading: false,
                    };
                }
                 case CustomerActionTypes.MULTIPLE_CUSTOMERS_UPD : {
                    return {
                        ...state,
                        multipleCustomerUPD: action.payload,
                        loading: false,
                    };
                }
                case CustomerActionTypes.SALE_MAN: {
                    return {
                        ...state,
                        saleMan: action.payload,
                        modalLoading: false,
                    };
                }
                case CustomerActionTypes.CUST_GROUP: {
                    return {
                        ...state,
                        custGroups: action.payload,
                        modalLoading: false,
                    };
                }
                case CustomerActionTypes.TERM_ID: {
                    return {
                        ...state,
                        termId: action.payload,
                        modalLoading: false,
                    };
                }
                case CustomerActionTypes.SALE_CAT: {
                    return {
                        ...state,
                        saleCate: action.payload,
                        modalLoading: false,
                    };
                }
                case CustomerActionTypes.OPEN_BALANCE: {
                    return {
                        ...state,
                        openBalance: action.payload,
                        loading: false,
                    };
                }
                case CustomerActionTypes.UPD_BALANCE: {
                    return {
                        ...state,
                        updOpenBalance: action.payload,
                        loading: false,
                    };
                }
                case CustomerActionTypes.ADD_CARRIER: {
                    return {
                        ...state,
                        carrier: action.payload,
                        loading: false,
                    };
                }
                case CustomerActionTypes.ADD_CASHCOUNTER: {
                    return {
                        ...state,
                        cashcounter: action.payload,
                        loading: false,
                    };
                }
                
                
                
                default:
                    return { ...state };
            }

        case CustomerActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                
                case CustomerActionTypes.ADD_CUST: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        custAdded: null,
                        loading: false,
                    };
                }
                case CustomerActionTypes.MULTIPLE_CUSTOMERS: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        multipleCustomer: null,
                        loading: false,
                    };
                }
                case CustomerActionTypes.MULTIPLE_CUSTOMERS_UPD: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        multipleCustomerUPD: null,
                        loading: false,
                    };
                }

                case CustomerActionTypes.UPD_CUST: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        custUpdated: null,
                        loading: false,
                    };
                }
                case CustomerActionTypes.CUST_GROUP: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        custGroups: null,
                        modalLoading: false,
                    };
                }
                case CustomerActionTypes.SALE_MAN: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        saleMan: null,
                        modalLoading: false,
                    };
                }
                case CustomerActionTypes.SALE_CAT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        saleCate: null,
                        modalLoading: false,
                    };
                }
                case CustomerActionTypes.OPEN_BALANCE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        openBalance: null,
                        loading: false,
                    };
                }
                case CustomerActionTypes.UPD_BALANCE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        updOpenBalance: null,
                        loading: false,
                    };
                }
                case CustomerActionTypes.ADD_CARRIER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        carrier: null,
                        loading: false,
                    };
                }
                case CustomerActionTypes.ADD_CASHCOUNTER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        cashcounter: null,
                        loading: false,
                    };
                }
                case CustomerActionTypes.TERM_ID: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        termId: null,
                        modalLoading: false,
                    };
                }
                
                default:
                    return { ...state };
            }

        case CustomerActionTypes.ADD_CUST:
            return { ...state, loading: true, custAdded: null };
        case CustomerActionTypes.MULTIPLE_CUSTOMERS_UPD:
            return { ...state, loading: true, multipleCustomerUPD: null };
        case CustomerActionTypes.MULTIPLE_CUSTOMERS:
            return { ...state, loading: true, multipleCustomer: null };
        case CustomerActionTypes.UPD_CUST:
            return { ...state, loading: true, custUpdated: null };
        case CustomerActionTypes.UPD_BALANCE:
            return { ...state, loading: true, updOpenBalance: null };
        case CustomerActionTypes.OPEN_BALANCE:
            return { ...state, loading: true, openBalance: null };
        case CustomerActionTypes.CUST_GROUP:
            return { ...state, modalLoading: true, custGroups: null };
        case CustomerActionTypes.SALE_MAN:
            return { ...state, modalLoading: true, saleMan: null };
        case CustomerActionTypes.SALE_CAT:
            return { ...state, modalLoading: true, saleCate: null };
        case CustomerActionTypes.ADD_CARRIER:
            return { ...state, modalLoading: true, carrier: null };
        case CustomerActionTypes.TERM_ID:
            return { ...state, modalLoading: true, termId: null };
        case CustomerActionTypes.ADD_CASHCOUNTER:
            return { ...state, modalLoading: true, cashcounter: null };
        case CustomerActionTypes.RESET:
            return {
                ...state,
                loading: false,
                modalLoading: false,
                error: false,
                custAdded: null,
                custUpdated: null,
                custGroups: null,
                saleMan: null,
                multipleCustomerUPD: null,
                multipleCustomer: null,
                openBalance: null,
                updOpenBalance: null,
                carrier: null,
                cashcounter: null,
                saleCate: null,
                termId: null,
             };
        default:
            return { ...state };
    }
};

export default Customers;
