import { SupplierActionTypes } from './constants';


const INIT_STATE = {
    supplierAdded: null,
    supplierUpdMulti: null,
    supplierAddMulti: null,
    openBalance:null,
    updateSupplier: null,
    supplierGroups: null,
    loading: false,
    modalLoading: false,
};

type SupplierData = {
    suppliername:string; 
};
type UnitData = {
    unitname:string; 
};
type BrandData = {
    name:string; 
};
type ColorData = {
    name:string; 
};
type CategoryData = {
    name:string; 
};


type SupplierActionType = {
    type:
        | SupplierActionTypes.API_RESPONSE_SUCCESS
        | SupplierActionTypes.API_RESPONSE_ERROR
        | SupplierActionTypes.ADD_SUPPLIER
        | SupplierActionTypes.UPD_SUPPLIER
        | SupplierActionTypes.VEN_GROUP
        | SupplierActionTypes.UPD_MULTIPLE_SUPPLIER
        | SupplierActionTypes.OPEN_VEN_BALANCE
        | SupplierActionTypes.ADD_MULTIPLE_SUPPLIER
        | SupplierActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: SupplierData | {};
        error?: string;
    };
};

type State = {
    item?: SupplierData | {};
    loading?: boolean;
    value?: boolean;
};

const Supplier = (state: State = INIT_STATE, action: SupplierActionType): any => {
    switch (action.type) {
        case SupplierActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case SupplierActionTypes.ADD_SUPPLIER: {
                    return {
                        ...state,
                        supplierAdded: action.payload,
                        loading: false,
                    };
                }
                case SupplierActionTypes.OPEN_VEN_BALANCE: {
                    return {
                        ...state,
                        openBalance: action.payload,
                        loading: false,
                    };
                }
                case SupplierActionTypes.UPD_SUPPLIER: {
                    return {
                        ...state,
                        updateSupplier: action.payload,
                        loading: false,
                    };
                }
                case SupplierActionTypes.ADD_MULTIPLE_SUPPLIER: {
                    return {
                        ...state,
                        supplierAddMulti: action.payload,
                        loading: false,
                    };
                }
                case SupplierActionTypes.UPD_MULTIPLE_SUPPLIER: {
                    return {
                        ...state,
                        supplierUpdMulti: action.payload,
                        loading: false,
                    };
                }
                case SupplierActionTypes.VEN_GROUP: {
                    return {
                        ...state,
                        supplierGroups: action.payload,
                        modalLoading: false,
                    };
                }
                default:
                    return { ...state };
                }

        case SupplierActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                
                case SupplierActionTypes.ADD_SUPPLIER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        supplierAdded: null,
                        loading: false,
                    };
                }
                case SupplierActionTypes.OPEN_VEN_BALANCE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        openBalance: null,
                        loading: false,
                    };
                }
                case SupplierActionTypes.ADD_MULTIPLE_SUPPLIER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        supplierAddMulti: null,
                        loading: false,
                    };
                }
                case SupplierActionTypes.UPD_MULTIPLE_SUPPLIER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        supplierUpdMulti: null,
                        loading: false,
                    };
                }
                case SupplierActionTypes.UPD_SUPPLIER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        updateSupplier: null,
                        loading: false,
                    };
                }
                case SupplierActionTypes.VEN_GROUP: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        supplierGroups: null,
                        modalLoading: false,
                    };
                }
                
                default:
                    return { ...state };
            }

        case SupplierActionTypes.ADD_SUPPLIER:
            return { ...state, loading: true, supplierAdded: null };
        case SupplierActionTypes.OPEN_VEN_BALANCE:
            return { ...state, loading: true, openBalance: null };
        case SupplierActionTypes.ADD_MULTIPLE_SUPPLIER:
            return { ...state, loading: true, supplierAddMulti: null };
        case SupplierActionTypes.UPD_MULTIPLE_SUPPLIER:
            return { ...state, loading: true, supplierUpdMulti: null };
        case SupplierActionTypes.UPD_SUPPLIER:
            return { ...state, loading: true, updateSupplier: null };
        case SupplierActionTypes.VEN_GROUP:
            return { ...state, modalLoading: true, supplierGroups: null };
        case SupplierActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                supplierAdded: null,
                updateSupplier: null,
                supplierUpdMulti: null,
                openBalance: null,
                supplierAddMulti: null,
                supplierGroups: null,
             };
        default:
            return { ...state };
    }
};

export default Supplier;
