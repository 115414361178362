// useFinancialYear.js
import { useState } from 'react';
import axios from 'axios';

export default function useFinancialYear(){
    const [finYear, setFinYear] = useState([]);
    const [selectFinYear, setSelectYear] = useState(null);
    const [finYearRes, setfinYearRes] = useState(null);
    const finYearOptions = async () => {
        try {
            const res = await axios.get(`/get-financial-years`);
            console.log('Fetched financial years:', res.data.financialyears); // Log the fetched data
            setFinYear(res.data.financialyears);
            let year = res.data.financialyears.find(year => year.select === 'selected');
            if (year) {
                setSelectYear(year);
                callBranchAPI(year.value);
            } else {
                setSelectYear(res.data.financialyears[0]);
                callBranchAPI(res.data.financialyears[0].value);
            }
        } catch (error) {
            console.error("Error fetching financial years", error);
        }
    };  

    const callBranchAPI = async (id) => {
        try {
            const res = await axios.put(`save-active-financial-year/${id}`, { id });
            setfinYearRes(res.data);
        } catch (error) {
            console.error("Error saving financial year", error);
        }
    };

    const handleChange = (option) => {
        setSelectYear(option);
        callBranchAPI(option.value);
    };

    return { finYear, selectFinYear, finYearRes, handleChange ,finYearOptions};
};
