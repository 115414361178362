import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// helpers
import {
    addEmp as addEmploy,updEmp,addDes_API,addDep_API,addAsset_API,
    addBonus_API,
    addDoctype_API,addJobtype_API,addQualification_API,addNationality_API, addWorkplaces_API,addGrades_API,addGroups_API,addShift_API,addRelations_API,addOccupation_API,openEmpBalances} from '../../helpers';

// actions
import { employeeApiResponseSuccess, employeeApiResponseError } from './actions';

// constants
import { EmployeeActionTypes } from './constants';

type EmpData = {
    payload: {
       id:string; formdata:object;bonusgrid:object;assetsgrid:object;backgroundgrid:object;documentgrid:object;dependsgrid:object;jobperiodgrid:object;incrementgrid:object
    };
    type: string;
};
type modalData = {
    payload: {name:string;};
    type: string;
};
type ShiftData = {
    payload: {name:string;timein:string;timeout:string;inbefore:string;inafter:string;outbefore:string;outafter:string;applydate:string;status:string;};
    type: string;
};

type balanceType = {
    payload: {
        entrydate: string;rowisedata: Object;
    };
    type: string;
};

const api = new APICore();
// const [getRes , setRes] = useState()
export const setResponse = {
    newResponse:null
}


function* add({ payload: {formdata,bonusgrid,assetsgrid,backgroundgrid,documentgrid,dependsgrid,jobperiodgrid,incrementgrid} }: EmpData): SagaIterator {
    try {
        const response = yield call(addEmploy, {formdata,bonusgrid,assetsgrid,backgroundgrid,documentgrid,dependsgrid,jobperiodgrid,incrementgrid});
        setResponse['newResponse'] = response;
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_EM, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_EM, error));
            }
}
function* upd({ payload: {id,formdata,bonusgrid,assetsgrid,backgroundgrid,documentgrid,dependsgrid,jobperiodgrid,incrementgrid} }: EmpData): SagaIterator {
    try {
        const response = yield call(updEmp, {id,formdata,bonusgrid,assetsgrid,backgroundgrid,documentgrid,dependsgrid,jobperiodgrid,incrementgrid});
        setResponse['newResponse'] = response;
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.UPD_EM, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.UPD_EM, error));
            }
}
function* addDesig({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addDes_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_DESIG, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_DESIG, error));
            }
}
function* addDep({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addDep_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_DEPART, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_DEPART, error));
            }
}
function* addJobType({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addJobtype_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_JOBTYPE, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_JOBTYPE, error));
            }
}
function* addShift({ payload: {name,timein,timeout,inbefore,inafter,outbefore,outafter,applydate,status} }: ShiftData): SagaIterator {
    try {
        const response = yield call(addShift_API, {name,timein,timeout,inbefore,inafter,outbefore,outafter,applydate,status});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_SHIFT, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_SHIFT, error));
            }
}
function* addQualification({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addQualification_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_QUALI, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_QUALI, error));
            }
}
function* addGrades({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addGrades_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_GRADES, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_GRADES, error));
            }
}
function* addGroups({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addGroups_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_GROUPS, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_GROUPS, error));
            }
}
function* addRela({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addRelations_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_RELA, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_RELA, error));
            }
}
function* addOccu({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addOccupation_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_OCCU, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_OCCU, error));
            }
}
function* addNationality({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addNationality_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_NATI, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_NATI, error));
            }
}
function* addWorkplaces({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addWorkplaces_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_WORK, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_WORK, error));
            }
}
function* addAsset({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addAsset_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_ASSET, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_ASSET, error));
            }
}
function* addBonus({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addBonus_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_BONUS, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_BONUS, error));
            }
}
function* addDoc({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addDoctype_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_DOC, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_DOC, error));
            }
}

function* addVenBalance ({payload:{entrydate,rowisedata}}:balanceType):  SagaIterator{
    try{
        const response = yield call(openEmpBalances,{entrydate,rowisedata})
        const balance = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.OPEN_EMPLOYEE_BALANCE,balance))
    }catch(error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.OPEN_EMPLOYEE_BALANCE, error)); 
    }
}


export function* watchAdd(): any {
    yield takeEvery(EmployeeActionTypes.ADD_EM, add);
}
export function* watchUpd(): any {
    yield takeEvery(EmployeeActionTypes.UPD_EM, upd);
}
export function* watchDes(): any {
    yield takeEvery(EmployeeActionTypes.ADD_DESIG, addDesig);
}
export function* watchDep(): any {
    yield takeEvery(EmployeeActionTypes.ADD_DEPART, addDep);
}
export function* watchJobtype(): any {
    yield takeEvery(EmployeeActionTypes.ADD_JOBTYPE, addJobType);
}
export function* watchShift(): any {
    yield takeEvery(EmployeeActionTypes.ADD_SHIFT, addShift);
}
export function* watchQual(): any {
    yield takeEvery(EmployeeActionTypes.ADD_QUALI, addQualification);
}
export function* watchGrades(): any {
    yield takeEvery(EmployeeActionTypes.ADD_GRADES, addGrades);
}
export function* watchGroups(): any {
    yield takeEvery(EmployeeActionTypes.ADD_GROUPS, addGroups);
}
export function* watchRela(): any {
    yield takeEvery(EmployeeActionTypes.ADD_RELA, addRela);
}
export function* watchOccu(): any {
    yield takeEvery(EmployeeActionTypes.ADD_OCCU, addOccu);
}
export function* watchNati(): any {
    yield takeEvery(EmployeeActionTypes.ADD_NATI, addNationality);
}
export function* watchWork(): any {
    yield takeEvery(EmployeeActionTypes.ADD_WORK, addWorkplaces);
}
export function* watchAsset(): any {
    yield takeEvery(EmployeeActionTypes.ADD_ASSET, addAsset);
}
export function* watchBonus(): any {
    yield takeEvery(EmployeeActionTypes.ADD_BONUS, addBonus);
}
export function* watchDoctype(): any {
    yield takeEvery(EmployeeActionTypes.ADD_DOC, addDoc);
}

export function* watchOpenBalance():any{
    yield takeEvery(EmployeeActionTypes.OPEN_EMPLOYEE_BALANCE,addVenBalance)
}


function* employeeSaga() {
    yield all([fork(watchAdd)]);
    yield all([fork(watchShift)]);
    yield all([fork(watchOpenBalance)]);
    yield all([fork(watchUpd)]);
    yield all([fork(watchGroups)]);
    yield all([fork(watchRela)]);
    yield all([fork(watchOccu)]);
    yield all([fork(watchNati)]);
    yield all([fork(watchGrades)]);
    yield all([fork(watchDes)]);
    yield all([fork(watchDep)]);
    yield all([fork(watchWork)]);
    yield all([fork(watchJobtype)]);
    yield all([fork(watchQual)]);
    yield all([fork(watchAsset)]);
    yield all([fork(watchBonus)]);
    yield all([fork(watchDoctype)]);
}

export default employeeSaga;
