import { APICore } from './apiCore';
const api = new APICore();
function addServices_API(params: {orderid:string,entrydate:string,name:string,phone:string,address:string,sourceid:string,customerid:string,salestype:string,servicepersonid:string,reference:string,notes:string,discount:string,amount:string,netamount:string,toolid:string,tax:string,taxamount:string,bankreceived:string,bankid:string,cashreceived:string,pickuppostal:string ,pickupaddress:string,dropoffpostal:string,dropoffaddress:string,orderdetdata:Object}) {
    const baseUrl = '/add-services-invoice';
    return api.create(`${baseUrl}`, params);
}
function addServicesOrder_API(params: {entrydate:string,sourceid:string,customerid:string,salestype:string,servicepersonid:string,reference:string,notes:string,discount:string,amount:string,netamount:string,toolid:string,tax:string,taxamount:string,pickuppostal:string ,pickupaddress:string,dropoffpostal:string,dropoffaddress:string, orderdetdata:Object}) {
    const baseUrl = '/add-servicesorder';
    return api.create(`${baseUrl}`, params);
}
function addQuery_API(params: {name:string;}) {
    const baseUrl = '/add-querysource';
    return api.create(`${baseUrl}`, params);
}
function addTools_API(params: {name:string;}) {
    const baseUrl = '/add-toolsmachine';
    return api.create(`${baseUrl}`, params);
}
function addServicesPerson_API(params: {name:string;}) {
    const baseUrl = '/add-servicesperson';
    return api.create(`${baseUrl}`, params);
}
function updServices_API(params: {id:string,orderid:string,entrydate:string,name:string,phone:string,address:string,sourceid:string,customerid:string,salestype:string,servicepersonid:string,reference:string,notes:string,discount:string,amount:string,netamount:string,toolid:string,tax:string,taxamount:string,bankreceived:string,bankid:string,cashreceived:string,pickuppostal:string ,pickupaddress:string,dropoffpostal:string,dropoffaddress:string,orderdetdata:Object}) {
    const baseUrl = `/update-servicesinvoice/${params.id}`;
    return api.create(`${baseUrl}`, params);
}
function updServicesOrder_API(params: {id:string,entrydate:string,sourceid:string,customerid:string,salestype:string,servicepersonid:string,reference:string,notes:string,discount:string,amount:string,netamount:string,toolid:string,tax:string,taxamount:string,pickuppostal:string ,pickupaddress:string,dropoffpostal:string,dropoffaddress:string, orderdetdata:Object}) {
    const baseUrl = `/update-servicesorder/${params.id}`;
    return api.create(`${baseUrl}`, params);
}
export { addServicesOrder_API,
    updServicesOrder_API,addServices_API,updServices_API,addQuery_API,addTools_API,addServicesPerson_API};
