import { AppsActionTypes } from './constants';

const INIT_STATE = {
  calendar: null,
  contacts: null,
  tasks: null,
  loading: false,
  error: null,
  priorityRes: null,
  comment: null,
  messageStatus: null,
  positionRes: null,
};

const Apps = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AppsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AppsActionTypes.CALENDAR_EVENT:
          return {
            ...state,
            calendar: action.payload,
          };
          case AppsActionTypes.ADD_CONTACT:
          return {
            ...state,
            contacts: action.payload,
          };
          case AppsActionTypes.ADD_TASKS:
          return {
            ...state,
            tasks: action.payload,
          };
          case AppsActionTypes.ADD_PRIORITY:
          return {
            ...state,
            priorityRes: action.payload,
          };
          case AppsActionTypes.ADD_POSITION:
          return {
            ...state,
            positionRes: action.payload,
          };
          case AppsActionTypes.ADD_COMMENT:
          return {
            ...state,
            comment: action.payload,
          };
          case AppsActionTypes.ADD_MESSAGE:
          return {
            ...state,
            messageStatus: action.payload,
          };
        default:
          return { ...state };
      }
    case AppsActionTypes.API_RESPONSE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        calendar: null,
        tasks: null,
        contacts: null,
        priorityRes: null,
        positionRes: null,
        comment:null,
        messageStatus:null,
        loading: true,
      };
    case AppsActionTypes.CALENDAR_EVENT:
      return { ...state, loading: true, calendar: null };
    case AppsActionTypes.ADD_CONTACT:
      return { ...state, loading: true, contacts: null };
    case AppsActionTypes.ADD_TASKS:
      return { ...state, loading: true, tasks: null };
    case AppsActionTypes.ADD_COMMENT:
      return { ...state, loading: true, comment: null };
    case AppsActionTypes.ADD_MESSAGE:
      return { ...state, loading: true, messageStatus: null };
    case AppsActionTypes.RESET:
      return { ...state, loading: false, calendar: null,contacts: null,tasks: null,priorityRes: null,positionRes: null,comment: null,messageStatus: null, error: false };
    default:
      return { ...state };
  }
};

export default Apps;
