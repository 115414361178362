export enum SalesActionTypes {
    API_RESPONSE_SUCCESS = '@@sales/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@sales/API_RESPONSE_ERROR',
    ADD_Inv = '@@sales/ADD_Inv',
    ADD_SALEINVOICE = '@@sales/ADD_SALEINVOICE',
    UPD_SALEINVOICE = '@@sales/UPD_SALEINVOICE',
    ADD_INVGRID = '@@sales/ADD_INVGRID',
    PAYMENT_RECEIPT = '@@sales/PAYMENT_RECEIPT',
    ADD_INVOICERETURN = '@@sales/ADD_INVOICERETURN',
    UPD_INVOICERETURN = '@@sales/UPD_INVOICERETURN',
    UPD_PAYMENT_RECEIPT = '@@sales/UPD_PAYMENT_RECEIPT',
    ADD_SALEORDER = '@@sales/ADD_SALEORDER',
    UPD_SALEORDER = '@@sales/UPD_SALEORDER',
    ADD_DELNOTES = '@@sales/ADD_DELNOTES',
    UPD_DELNOTES = '@@sales/UPD_DELNOTES',
    CREDIT_NOTE = '@@sales/CREDIT_NOTE',
    UPD_CREDIT_NOTE = '@@sales/UPD_CREDIT_NOTE',
    DEBIT_NOTE = '@@sales/DEBIT_NOTE',
    UPD_DEBIT = '@@sales/UPD_DEBIT',
    RESET = '@@sales/RESET',
}