export enum AppsActionTypes {
    API_RESPONSE_SUCCESS = '@@apps/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@apps/API_RESPONSE_ERROR',
    CALENDAR_EVENT = '@@apps/CALENDAR_EVENT',
    ADD_CONTACT = '@@apps/ADD_CONTACT',
    ADD_TASKS = '@@apps/ADD_TASKS',
    ADD_PRIORITY = '@@apps/ADD_PRIORITY',
    ADD_COMMENT = '@@apps/ADD_COMMENT',
    ADD_POSITION = '@@apps/ADD_POSITION',
    ADD_MESSAGE = '@@apps/ADD_MESSAGE',
    RESET = '@@apps/RESET',
}