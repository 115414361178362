import { APICore } from './apiCore';

const api = new APICore();
function addPurchaseInv(params: { entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,grnvoucherid:string,purchasedetdata:Object }) {
    const baseUrl = '/add-purchase';
    return api.create(`${baseUrl}`, params);
}
function updPurchaseInv(params: { id:string,entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,grnvoucherid:string,purchasedetdata:Object }) {
    const baseUrl = `/update-purchase/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function addPurchaseOrder(params: { entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,receivedate:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,purchasedetdata:Object }) {
    const baseUrl = '/add-purchaseorder';
    return api.create(`${baseUrl}`, params);
}
function updPurchaseOrder(params ) {
    const baseUrl = `/update-purchaseorder/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function addPurchaseReturn_API(params: { entrydate:string,purchasenumber:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,purchasedetdata:Object }) {
    const baseUrl = '/add-purchasereturn';
    return api.create(`${baseUrl}`, params);
}
function updPurchaseReturn_API(params: { id:string,entrydate:string,purchasenumber:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,purchasedetdata:Object }) {
    const baseUrl = `/update-purchasereturn/${params.id}`;
    return api.update(`${baseUrl}`, params);
}


function addGRN_API(params: { entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,receivedate:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,orderid:string,purchasedetdata:Object }) {
    const baseUrl = '/add-grn';
    return api.create(`${baseUrl}`, params);
}
/*
function addGRN_API(params) {
    const baseUrl = '/add-grn';
    return api.create(`${baseUrl}`, params);
}

function updGRN_API(params: { id:string,entrydate:string,orderid:string,supplierid:string,transtype:string,reference:string,notes:string,totalprice:string,receivedate:string,subtotal:string,netamount:string,name:string,phone:string,address:string,purchasedetdata:Object }) {
    const baseUrl = `/update-grn/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
*/

function updGRN_API(params) {
    const baseUrl = `/update-grn/${params.id}`;
    return api.update(`${baseUrl}`, params);
}

function add_supplierPayment(params) {
    const baseUrl = `/add-supplier-payment`;
    return api.create(`${baseUrl}`, params);
}

function upd_supplierPayment(params) {
    const baseUrl = `/update-supplier-payment/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function supAddCedit_API(params) {
    const baseUrl = '/add-supplier-creditnote';
    return api.create(`${baseUrl}`, params);
    
}
function supServiceBill_API(params) {
    const baseUrl = '/add-supplier-servicesbill';
    return api.create(`${baseUrl}`, params);
    
}
function supUpdCedit_API(params) {
    const baseUrl = '/update-supplier-creditnote/'+params.id;
    return api.create(`${baseUrl}`, params);
    
}
function UpdServiceBill_API(params) {
    const baseUrl = '/update-supplier-servicesbill/'+params.id;
    return api.create(`${baseUrl}`, params);
    
}
function supAddDebit_API(params) {
    const baseUrl = '/add-supplier-debitnote';
    return api.create(`${baseUrl}`, params);
    
}
function supUpdDebit_API(params) {
    const baseUrl = '/update-supplier-debitnote/'+params.id;
    return api.create(`${baseUrl}`, params);
    
}

export {supAddCedit_API,
supServiceBill_API,
    supUpdCedit_API,
    UpdServiceBill_API,
    supAddDebit_API,
    supUpdDebit_API,addPurchaseInv,updPurchaseInv,add_supplierPayment,upd_supplierPayment,addPurchaseReturn_API,
updPurchaseReturn_API,addPurchaseOrder,
updPurchaseOrder,addGRN_API,
updGRN_API};
