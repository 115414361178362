// constants
import { PurchaseActionTypes } from './constants';

export type PurchaseActionType = {
    type:
        | PurchaseActionTypes.API_RESPONSE_SUCCESS
        | PurchaseActionTypes.API_RESPONSE_ERROR
        | PurchaseActionTypes.RESET
        | PurchaseActionTypes.ADD_Purchase
        | PurchaseActionTypes.ADD_PURCHASEINV
        | PurchaseActionTypes.UPD_PURCHASEINV
        | PurchaseActionTypes.UPD_PURCHASE_RETURN
        | PurchaseActionTypes.ADD_PURCHASE_RETURN
        | PurchaseActionTypes.ADD_PURCHASEORDER
        | PurchaseActionTypes.UPD_PURCHASEORDER
        | PurchaseActionTypes.ADD_GRN
        | PurchaseActionTypes.UPD_GRN
        | PurchaseActionTypes.ADD_PURCHASEGRID
         | PurchaseActionTypes.SUPP_CREDIT_NOTE
         | PurchaseActionTypes.ADD_SERVICE_BILL
         | PurchaseActionTypes.SUPP_DEBIT_NOTE
         | PurchaseActionTypes.SUPP_UPD_CREDIT_NOTE
         | PurchaseActionTypes.UPD_SERVICES_BILL
         |  PurchaseActionTypes.SUPP_UPD_DEBIT;
    payload: {} | string;
};

type InvoiceData = {
    name: string;
    };

// common success
export const purchaseApiResponseSuccess = (actionType: string, data: InvoiceData | {}): PurchaseActionType => ({
    type: PurchaseActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const purchaseApiResponseError = (actionType: string, error: string): PurchaseActionType => ({
    type: PurchaseActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addPurchase = (name:string): PurchaseActionType => ({
    type: PurchaseActionTypes.ADD_Purchase,
    payload: {name, },
});

export const addPurchaseInv = (entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,grnvoucherid:string,purchasedetdata:Object): PurchaseActionType => ({
    type: PurchaseActionTypes.ADD_PURCHASEINV,
    payload: {entrydate,supplierid,transtype,reference,notes,freight,totalDiscount,totalprice,AdvancePayment,subtotal,expense,netamount,custfreight,custexpense,name,phone,address,invoicetermsid,shipped_via,trackNumber,grnvoucherid,purchasedetdata },
});
export const updPurchaseInv = (id:string,entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,grnvoucherid:string,purchasedetdata:Object): PurchaseActionType => ({
    type: PurchaseActionTypes.UPD_PURCHASEINV,
    payload: {id,entrydate,supplierid,transtype,reference,notes,freight,totalDiscount,totalprice,AdvancePayment,subtotal,expense,netamount,custfreight,custexpense,name,phone,address,invoicetermsid,shipped_via,trackNumber,grnvoucherid,purchasedetdata },
});
export const addPurchaseOrder = (entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,receivedate:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,purchasedetdata:Object): PurchaseActionType => ({
    type: PurchaseActionTypes.ADD_PURCHASEORDER,
    payload: {entrydate,supplierid,transtype,reference,notes,freight,totalDiscount,totalprice,receivedate,subtotal,expense,netamount,custfreight,custexpense,name,phone,address,invoicetermsid,shipped_via,trackNumber,purchasedetdata },
});
export const updPurchaseOrder = (id:string,entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,receivedate:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,purchasedetdata:Object): PurchaseActionType => ({
    type: PurchaseActionTypes.UPD_PURCHASEORDER,
    payload: {id,entrydate,supplierid,transtype,reference,notes,freight,totalDiscount,totalprice,receivedate,subtotal,expense,netamount,custfreight,custexpense,name,phone,address,invoicetermsid,shipped_via,trackNumber,purchasedetdata },
});
export const addPurReturn = (entrydate:string,purchasenumber:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,purchasedetdata:Object): PurchaseActionType=> ({
   type: PurchaseActionTypes.ADD_PURCHASE_RETURN,
   payload: {entrydate,purchasenumber,supplierid,transtype,reference,notes,freight,totalDiscount,totalprice,AdvancePayment,subtotal,expense,netamount,custfreight,custexpense,name,phone,address,invoicetermsid,shipped_via,trackNumber,purchasedetdata},
});
export const editPurReturn = (id:string,entrydate:string,purchasenumber:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,purchasedetdata:Object): PurchaseActionType=> ({
    type: PurchaseActionTypes.UPD_PURCHASE_RETURN,
    payload: {id,entrydate,purchasenumber,supplierid,transtype,reference,notes,freight,totalDiscount,totalprice,AdvancePayment,subtotal,expense,netamount,custfreight,custexpense,name,phone,address,invoicetermsid,shipped_via,trackNumber,purchasedetdata },
});

export const addGRN = (entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,receivedate:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,orderid:string,purchasedetdata:Object): PurchaseActionType => ({
    type: PurchaseActionTypes.ADD_GRN,
    payload: {entrydate,supplierid,transtype,reference,notes,freight,totalDiscount,totalprice,receivedate,subtotal,expense,netamount,custfreight,custexpense,name,phone,address,invoicetermsid,shipped_via,trackNumber,orderid,purchasedetdata },
});

/*
export const addGRN = (entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,receivedate:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,shipped_via:string,trackNumber:string,orderid:string,purchasedetdata:Object): PurchaseActionType => ({
    type: PurchaseActionTypes.ADD_GRN,
    payload: {entrydate,supplierid,transtype,reference,notes,freight,totalDiscount,totalprice,receivedate,subtotal,expense,netamount,custfreight,custexpense,name,phone,address,invoicetermsid,shipped_via,trackNumber,orderid,purchasedetdata },
});
*/

export const updGRN = (id:string,entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,receivedate:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicetermsid:string,orderid:string,shipped_via:string,trackNumber:string,purchasedetdata:Object): PurchaseActionType => ({
    type: PurchaseActionTypes.UPD_GRN,
    payload: {id,entrydate,supplierid,transtype,reference,notes,freight,totalDiscount,totalprice,receivedate,subtotal,expense,netamount,custfreight,custexpense,name,phone,address,invoicetermsid,shipped_via,trackNumber,orderid,purchasedetdata },
});
/*
export const updGRN = (id:string,entrydate:string,orderid:string,supplierid:string,transtype:string,reference:string,notes:string,totalprice:string,receivedate:string,subtotal:string,netamount:string,name:string,phone:string,address:string,purchasedetdata:Object): PurchaseActionType=> ({
    type: PurchaseActionTypes.UPD_GRN,
    payload: {id,entrydate,orderid,supplierid,transtype,reference,notes,totalprice,receivedate,subtotal,netamount,name,phone,address,purchasedetdata },
});

*/
export const addPurchaseGrid = (data:Object): PurchaseActionType => ({
    type: PurchaseActionTypes.ADD_PURCHASEGRID,
    payload: {data },
});
export const supplier_payment = (supplierid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string,reference:string) => ({
    type: PurchaseActionTypes.SUPPLIER_PAYMENT,
    payload: {supplierid,amount,paymethod,salesmanid,description,entrydate,checkNum,bank,duedate,deposit,reference },
});
export const upd_supplier_payment = (id:string,supplierid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string,reference:string) => ({
    type: PurchaseActionTypes.UPD_SUPPLIER_PAYMENT,
    payload: {id,supplierid,amount,paymethod,salesmanid,description,entrydate,checkNum,bank,duedate,deposit,reference },
});
export const addSupCreditNote = (entrydate,supplierid,description,totalamount,voucherdetdata) => ({
    type: PurchaseActionTypes.SUPP_CREDIT_NOTE,
    payload: {
    entrydate,supplierid,description,totalamount,voucherdetdata
    },
});

export const addServiceBill = (entrydate,supplierid,description,totalamount,voucherdetdata) => ({
    type: PurchaseActionTypes.ADD_SERVICE_BILL,
    payload: {
    entrydate,supplierid,description,totalamount,voucherdetdata
    },
});
export const updServiceBill = (id,entrydate,supplierid,description,totalamount,voucherdetdata) => ({
    type: PurchaseActionTypes.UPD_SERVICES_BILL,
    payload: {
    id,entrydate,supplierid,description,totalamount,voucherdetdata
    },
});

export const addSupDebitNote = (entrydate,supplierid,description,totalamount,voucherdetdata) => ({
    type: PurchaseActionTypes.SUPP_DEBIT_NOTE,
    payload: {
    entrydate,supplierid,description,totalamount,voucherdetdata
    },
});
export const updSupCreditNote = (id,entrydate,supplierid,description,totalamount,voucherdetdata) => ({
    type: PurchaseActionTypes.SUPP_UPD_CREDIT_NOTE,
    payload: {
    id,entrydate,supplierid,description,totalamount,voucherdetdata
    },
});
export const updSupDebitNote = (id,entrydate,supplierid,description,totalamount,voucherdetdata) => ({
    type: PurchaseActionTypes.SUPP_UPD_DEBIT,
    payload: {
   id, entrydate,supplierid,description,totalamount,voucherdetdata
    },
});

export const resetPurchase = (): PurchaseActionType => ({
    type: PurchaseActionTypes.RESET,
    payload: {},
});
