import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

function addEmp(params: { formdata:object;bonusgrid:object;assetsgrid:object;backgroundgrid:object;documentgrid:object;dependsgrid:object;jobperiodgrid:object,incrementgrid:object}) {
  console.log('employee add---');
    const baseUrl = '/save-employee';
    return api.create(`${baseUrl}`, params);
}

function updEmp(params: { id:string;formdata:object;bonusgrid:object;assetsgrid:object;backgroundgrid:object;documentgrid:object;dependsgrid:object;jobperiodgrid:object,incrementgrid:object}) {
  console.log('employee add---');
    const baseUrl = '/update-employee/'+params.id;
    return api.update(`${baseUrl}`, params);
}
function addDes_API(params: {name:string;}) {
    const baseUrl = '/save-designation';
    return api.create(`${baseUrl}`, params);
}
function addDep_API(params: {name:string;}) {
    const baseUrl = '/save-department';
    return api.create(`${baseUrl}`, params);
}
function addJobtype_API(params: {name:string;}) {
    const baseUrl = '/save-jobtype';
    return api.create(`${baseUrl}`, params);
}
function addQualification_API(params: {name:string;}) {
    const baseUrl = '/save-qualification';
    return api.create(`${baseUrl}`, params);
}
function addRelations_API(params: {name:string;}) {
    const baseUrl = '/save-relation';
    return api.create(`${baseUrl}`, params);
}
function addOccupation_API(params: {name:string;}) {
    const baseUrl = '/save-occupation';
    return api.create(`${baseUrl}`, params);
}
function addGrades_API(params: {name:string;}) {
    const baseUrl = '/save-grade';
    return api.create(`${baseUrl}`, params);
}
function addWorkplaces_API(params: {name:string;}) {
    const baseUrl = '/save-workplace';
    return api.create(`${baseUrl}`, params);
}
function addNationality_API(params: {name:string;}) {
    const baseUrl = '/save-nationality';
    return api.create(`${baseUrl}`, params);
}
function addShift_API(params: {name:string;timein:string;timeout:string;inbefore:string;inafter:string;outbefore:string;outafter:string;applydate:string;status:string;}) {
    const baseUrl = '/save-shift';
    return api.create(`${baseUrl}`, params);
}
function addGroups_API(params: {name:string;}) {
    const baseUrl = '/save-employee-group';
    return api.create(`${baseUrl}`, params);
}
function addAsset_API(params: {name:string;}) {
    const baseUrl = '/save-assettype';
    return api.create(`${baseUrl}`, params);
}
function addBonus_API(params: {name:string;}) {
    const baseUrl = '/save-bonustype';
    return api.create(`${baseUrl}`, params);
}
function addDoctype_API(params: {name:string;}) {
    const baseUrl = '/save-documenttype';
    return api.create(`${baseUrl}`, params);
}
function openEmpBalances(params) {
    const baseUrl = '/add-employee-balance';
    return api.create(`${baseUrl}`, params);
    
}

axios.interceptors.response.use(
  (response) => {
      return response;
  },
);



export {addEmp,updEmp,addDes_API,addAsset_API,
addBonus_API,
addDoctype_API,
addGroups_API,
addGrades_API,
addQualification_API,
addRelations_API,
addOccupation_API,
addWorkplaces_API,
addDep_API,
addShift_API,
addNationality_API,
addJobtype_API,
openEmpBalances};
