import jwtDecode from 'jwt-decode';
import axios from 'axios';
import config from '../../config';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;

axios.interceptors.request.use(function(config) {
    return config;
  });
  
export const displaySystemError = () => {
    const systemErrorDiv = document.createElement('div');
    systemErrorDiv.className = "status d-flex justify-content-center align-items-center mt-5";
    const errorMessage = document.createElement('h1');
    errorMessage.style.color = '#76797c';
    errorMessage.textContent = 'System Error Occurred Please Wait...';
    systemErrorDiv.appendChild(errorMessage);
    document.body.appendChild(systemErrorDiv);
};  
  
// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        let setErrors;

    //     if (error && error.response) {
    //           Swal.fire({
    //     title: "System error occured!",
    //     icon: 'error',
    //      width: '300px',
    //      customClass: {
    //             confirmButton: 'rounded-pill',
    //             }
    //   });
    //     }
        if(error && error.response) {
            switch (error.response.status) {
                case 401:
                    message = 'Invalid username or password';
                    break;
                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                case 500:
                    console.log('System error occured.');
                    //displaySystemError();
                    break;
                default: {
                    message ="System error occured";
                    setErrors =error.response && error.response.data ? error.response.data['message'] : error.message || error;
                        
                }
            }
            if(setErrors){
            console.log(setErrors);
            } 
                
            return Promise.reject(message);
        }
    }
);

const AUTH_SESSION_KEY = 'user_Information';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null,activeBranch:any) => {
    if (token){ axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.common['activeBranch'] = activeBranch;
}
    
    else delete axios.defaults.headers.common['Authorization'];
};

const getUserFromCookie = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};
class APICore {
    /**
     * Fetches data from given url
     */
    get = (url: string, params: any) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }
        return response;
    };

    getFile = (url: string, params: any) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls: string, params: any) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */

     create = (url: string, data: any) => {
        if(url.includes("/update")){
            return axios.put(url, data);
        }
        return axios.post(url, data);
        
    };
     
    /**
     * Updates patch data
     */
    updatePatch = (url: string, data: any) => {
        return axios.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url: string, data: any) => {
        return axios.put(url, data);
    };

    /**
     * Deletes data
     */
    delete = (url: string) => {
        return axios.delete(url);
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config: any = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config: any = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };
    isUserAuthenticated = () => {
    const user = this.getLoggedInUser();

        if (!user) {
            return false;
        }
        const decoded: any = jwtDecode(user.token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
          return false;
        } else {
            return true;
        }
    };


    /*
    const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            // console.warn('access token expired');
            const responses = ()=>{
               Swal.fire({
                  title: 'Access token expired please login again',
                  icon: 'error',
                 width: '400px',
                 customClass: {
                confirmButton: 'rounded-pill',
                }
                }).then((res)=>{
                    if(res.isConfirmed){
            return false;
                    }
                })  
            }
         
                return false;
        } else {
            return true;
        }
    };
    */

    setLoggedInUser = (session: any) => {
        if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            sessionStorage.removeItem(AUTH_SESSION_KEY);
        }
    };
    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        return getUserFromCookie();
    };

    setUserInSession = (modifiedUser: any) => {
        let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const { token, user } = JSON.parse(userInfo);
            this.setLoggedInUser({ token, ...user, ...modifiedUser });
        }
    };
}

/*
Check if token available in session
*/
let user = getUserFromCookie();

if (user) {
    const { token,activeBranch } = user;
    let brachJson = sessionStorage.getItem('branch')
   let branch = brachJson?JSON.parse(brachJson)?.value:activeBranch;
    if (token) {
        setAuthorization(token,branch);
    }
}

export { APICore, setAuthorization };
