import { APICore } from './apiCore';

const api = new APICore();

function addInv(params: { name: string, }) {
  console.log('addinv---');
    const baseUrl = '/add-inv';
    return api.create(`${baseUrl}`, params);
}
function addInvSale(params: { entrydate:string,dnvoucherid:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicedetdata:Object }) {
  console.log('addinv---');
    const baseUrl = '/add-sale-invoice';
    return api.create(`${baseUrl}`, params);
}
function updInvSale(params: { id:string,entrydate:string,dnvoucherid:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicedetdata:Object }) {
  console.log('update sale inv---');
    const baseUrl = `/update-salesinvoice/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function add_delNotes(params: { entrydate:string,orderid:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicedetdata:Object }) {
    const baseUrl = '/add-deliverynote';
    return api.create(`${baseUrl}`, params);
}
function upd_delNotes(params: { id:string,entrydate:string,orderid:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicedetdata:Object }) {
  console.log('update sale inv---');
    const baseUrl = `/update-deliverynote/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function addORDSale(params: { entrydate:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,deliverydate:string,invoicedetdata:Object }) {
    const baseUrl = '/add-salesorder';
    return api.create(`${baseUrl}`, params);
}
function updORDSale(params: { id:string,entrydate:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,deliverydate:string,invoicedetdata:Object }) {
    const baseUrl = `/update-salesorder/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function addInvReturn_API(params: { entrydate:string,invoicenumber:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicedetdata:Object }) {
  console.log('addinv---');
    const baseUrl = '/add-invoice-return';
    return api.create(`${baseUrl}`, params);
}
function updInvReturn_API(params: { id:string,entrydate:string,invoicenumber:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,invoicedetdata:Object }) {
  console.log('update sale inv---');
    const baseUrl = `/update-invoicereturn/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function addInvGrid(params: { data:Object }) {
  console.log('addinv---');
    const baseUrl = '/add-inv';
    return api.create(`${baseUrl}`, params);
}
function add_payReceipt(params: { customerid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string,reference:string }) {
  console.log('add payment---');
    const baseUrl = '/add-receipt';
    return api.create(`${baseUrl}`, params);
}
function upd_payReceipt(params: { id:string,customerid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string ,reference:string}) {
  console.log('add payment---');
    const baseUrl = '/update-receipt/'+params.id;
    return api.update(`${baseUrl}`, params);
}
function addCedit_API(params) {
    const baseUrl = '/add-creditnote';
    return api.create(`${baseUrl}`, params);
    
}
function updCedit_API(params) {
    const baseUrl = '/update-creditnote/'+params.id;
    return api.create(`${baseUrl}`, params);
    
}
function addDebit_API(params) {
    const baseUrl = '/add-debitnote';
    return api.create(`${baseUrl}`, params);
    
}
function updDebit_API(params) {
    const baseUrl = '/update-debitnote/'+params.id;
    return api.create(`${baseUrl}`, params);
    
}
export {addCedit_API,
  updCedit_API,
  addDebit_API,
  updDebit_API, addORDSale,add_delNotes,
  upd_delNotes,
  updORDSale,addInvReturn_API,
updInvReturn_API, addInv,addInvGrid ,addInvSale,updInvSale,add_payReceipt,upd_payReceipt};
