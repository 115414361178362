
import { LayoutActionType } from './actions';
import { LayoutActionTypes, LayoutStateTypes } from './constants';



const INIT_STATE = {
    themesRes: null,
    loading: false,
    error:null,
};

const Themes = (state= INIT_STATE, action): any => {
    switch (action.type) {
        case LayoutActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case LayoutActionTypes.THEME: {
                    return {
                        ...state,
                        themesRes: action.payload,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
                }

        case LayoutActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                
                case LayoutActionTypes.THEME: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        themesRes: null,
                        loading: false,
                    };
                }
               
                default:
                    return { ...state };
            }

        case LayoutActionTypes.THEME:
            return { ...state, loading: true, themesRes: null };
       
        case LayoutActionTypes.RESET:
            return {
                ...state,
                loading: false,
                themesRes:null,
                error: false,
             };
        default:
            return { ...state };
    }
};

export default Themes;
