// constants
import { CustomerActionTypes } from './constants';

export type CustomerActionType = {
    type:
        | CustomerActionTypes.API_RESPONSE_SUCCESS
        | CustomerActionTypes.API_RESPONSE_ERROR
        | CustomerActionTypes.RESET
        | CustomerActionTypes.ADD_CUST
        | CustomerActionTypes.UPD_CUST
        | CustomerActionTypes.CUST_GROUP
        | CustomerActionTypes.SALE_MAN
        | CustomerActionTypes.SALE_CAT
        | CustomerActionTypes.OPEN_BALANCE
        | CustomerActionTypes.MULTIPLE_CUSTOMERS_UPD
        | CustomerActionTypes.MULTIPLE_CUSTOMERS
        | CustomerActionTypes.UPD_BALANCE
        | CustomerActionTypes.ADD_CARRIER
        | CustomerActionTypes.ADD_CASHCOUNTER
        | CustomerActionTypes.TERM_ID;  
    payload: {} | string;
};
type CustData = {
    id:string; name: string;urduName: string; cellphone: string; cellphonecomment: string; cnic:string; address1:string; cityid:string; country:string; entry_firstname:string; middle_name:string; entry_lastname:string; phone1: string; phone1comment: string; phone2: string; phone2comment: string;  email1: string; email1comment: string; email2: string; email2comment: string;   groupid:string;  salescategoryid:string; salesmanid:string; invoicetermsid:string; combineSupplierId:string; crlimit:string; discount:string; notes:string;disablecrlimit:string;};
// common success
export const customerApiResponseSuccess = (actionType: string, data: CustData | {}): CustomerActionType => ({
    type: CustomerActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const customerApiResponseError = (actionType: string, error: string): CustomerActionType => ({
    type: CustomerActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});
export const addCust = ( name: string,urduName:string, cellphone: string, cellphonecomment: string, cnic:string, address1:string, cityid:string, country:string, entry_firstname:string, middle_name:string, entry_lastname:string, phone1: string, phone1comment: string, phone2: string, phone2comment: string,  email1: string, email1comment: string, email2: string, email2comment: string,   groupid:string,  salescategoryid:string, salesmanid:string, invoicetermsid:string, combineSupplierId:string, crlimit:string, discount:string, notes:string,disablecrlimit:string,cellphonecode
    :string,phone1code:string,): CustomerActionType => ({
    type: CustomerActionTypes.ADD_CUST,
    payload: { name,urduName, cellphone, cellphonecomment, cnic, address1, cityid, country, entry_firstname, middle_name, entry_lastname, phone1, phone1comment, phone2, phone2comment,  email1, email1comment, email2, email2comment,   groupid,  salescategoryid, salesmanid, invoicetermsid, combineSupplierId, crlimit, discount, notes,disablecrlimit,cellphonecode,phone1code},
});
export const updCust = (id:string, name: string,urduName:string, cellphone: string, cellphonecomment: string, cnic:string, address1:string, cityid:string, country:string, entry_firstname:string, middle_name:string, entry_lastname:string, phone1: string, phone1comment: string, phone2: string, phone2comment: string,  email1: string, email1comment: string, email2: string, email2comment: string,   groupid:string,  salescategoryid:string, salesmanid:string, invoicetermsid:string, combineSupplierId:string, crlimit:string, discount:string, notes:string,disablecrlimit:string,cellphonecode
    :string,phone1code:string,): CustomerActionType => ({
    type: CustomerActionTypes.UPD_CUST,
    payload: {id, name,urduName, cellphone, cellphonecomment, cnic, address1, cityid, country, entry_firstname, middle_name, entry_lastname, phone1, phone1comment, phone2, phone2comment,  email1, email1comment, email2, email2comment,   groupid,  salescategoryid, salesmanid, invoicetermsid, combineSupplierId, crlimit, discount, notes,disablecrlimit,cellphonecode,phone1code },});
export const custGroup = (groupname: string): CustomerActionType => ({
    type: CustomerActionTypes.CUST_GROUP,
    payload: {groupname},
});
export const addOpenBalance = (entrydate:string,rowisedata:Object)=>({
    type:CustomerActionTypes.OPEN_BALANCE,
    payload:{entrydate,rowisedata}
})
export const upd_OpenBalance = (id:string,entrydate:string,rowisedata:Object)=>({
    type:CustomerActionTypes.UPD_BALANCE,
    payload:{id,entrydate,rowisedata}
})
export const manageSaleman = (name: string,address1: string,phone1: string,city: string,): CustomerActionType => ({
    type: CustomerActionTypes.SALE_MAN,
    payload: {name,address1,phone1,city},
});
export const manageSaleCat = (name: string): CustomerActionType => ({
    type: CustomerActionTypes.SALE_CAT,
    payload: {name},
});
export const addMultipleCustomers= (data_array)=> ({
    type: CustomerActionTypes.MULTIPLE_CUSTOMERS,
    payload: {data_array},
});
export const updMultipleCustomers = (data_array,id)=> ({
    type: CustomerActionTypes.MULTIPLE_CUSTOMERS_UPD,
    payload: {data_array,id},
});
export const manageCarrier = (name: string) => ({
    type: CustomerActionTypes.ADD_CARRIER,
    payload: {name},
});
export const manageCashcounter = (description: string,cashaccountid) => ({
    type: CustomerActionTypes.ADD_CASHCOUNTER,
    payload: {description,cashaccountid},
});
export const manageTermId = (verbal: string): CustomerActionType => ({
    type: CustomerActionTypes.TERM_ID,
    payload: {verbal},
});
export const resetCustomer = (): CustomerActionType => ({
    type: CustomerActionTypes.RESET,
    payload: {},
});
