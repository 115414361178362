export enum EmployeeActionTypes {
    API_RESPONSE_SUCCESS = '@@employee/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@employee/API_RESPONSE_ERROR',
    ADD_EM = '@@employee/ADD_EM',
    UPD_EM = '@@employee/UPD_EM',
    ADD_DESIG = '@@employee/ADD_DESIG',
    ADD_DEPART = '@@employee/ADD_DEPART',
    ADD_JOBTYPE = '@@employee/ADD_JOBTYPE',
    ADD_QUALI = '@@employee/ADD_QUALI',
    ADD_NATI = '@@employee/ADD_NATI',    
    ADD_WORK = '@@employee/ADD_WORK', 
    ADD_SHIFT = '@@employee/ADD_SHIFT', 
    ADD_GROUPS = '@@employee/ADD_GROUPS',
    ADD_GRADES = '@@employee/ADD_GRADES', 
    ADD_ASSET = '@@employee/ADD_ASSET',
    ADD_BONUS = '@@employee/ADD_BONUS',
    ADD_RELA = '@@employee/ADD_RELA',
    ADD_OCCU = '@@employee/ADD_OCCU',
    ADD_DOC = '@@employee/ADD_DOC',
    RESET = '@@employee/RESET',
    OPEN_EMPLOYEE_BALANCE = '@@employee/OPEN_EMPLOYEE_BALANCE',
}